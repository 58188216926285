<script setup>
import GuestLayout from "@/Layouts/GuestLayout.vue";
import useListings from "@/Components/Guest/Listings/useListings.js";
import Hero from "@/Components/Guest/Hero.vue";
import ListingsSection from "@/Components/Guest/Listings/ListingsSection.vue";
import { provide } from "vue";
import SubscribeToNewsletter from "@/Components/Guest/Blog/SubscribeToNewsletter.vue";
import FeaturedPosts from "@/Components/Guest/Blog/FeaturedPosts.vue";
import Faqs from "@/Components/Guest/Faqs.vue";

const props = defineProps({
    listings: Object,
    tags: Object,
    categories: Object,
    experiences: Object,
    commitments: Object,
    remoteStatuses: Object,
    benefits: Object,
    salaryRanges: Object,
    filters: Object,
    currentRoute: String,
    posts: Object,
});

const faqs = [
    {
        question: "Why should I subscribe to a Remote DXB plan?",
        answer: "Subscribing grants you access to a vast selection of remote and hybrid job opportunities in Dubai and the UAE. It connects you with UAE-based and international companies keen on remote work, offering listings for completely remote, hybrid, and flexible job roles.",
    },
    {
        question:
            "What are the subscription plans available, and what do they offer?",
        answer: "We offer two plans: a Monthly Plan for 18 AED, and a Yearly Plan for 60 AED. These plans provide full access to our job listings, catering to different needs—from short-term job searching to dedicated long-term employment scouting.",
    },
    {
        question: "What makes Remote DXB different from other job boards?",
        answer: "Remote DXB specializes in remote work opportunities within the UAE, providing a curated list of high-quality jobs from diverse industries. Unlike generic job boards, we focus solely on remote and flexible roles to match the unique lifestyle of our subscribers.",
    },
    {
        question: "Can I see some job listings before I subscribe?",
        answer: "While select job highlights are viewable, full access to job descriptions and applications are exclusive to subscribers. Our plans are affordably priced to give serious job seekers the insights and opportunities they need.",
    },
    {
        question: "Is there a free trial period for any of the plans?",
        answer: "We currently do not offer a free trial. However, our monthly plan is an excellent low-risk option to explore the full benefits of our service.",
    },
    {
        question: "How do I subscribe to a plan?",
        answer: "Simply select your plan on our subscription page, enter your payment details, and enjoy immediate access to our job listings.",
    },
    {
        question: "Can I cancel or change my subscription plan?",
        answer: "Yes, you have the flexibility to cancel or switch plans at any time to suit your job search needs.",
    },
    {
        question: "What types of companies will I find on Remote DXB?",
        answer: "Our platform features a variety from startups to multinational corporations, all offering remote or hybrid positions. This includes local UAE businesses and international firms interested in hiring in the UAE.",
    },
];

useListings(props);
</script>

<template>
    <GuestLayout :dark="true" :absolute-header="true">
        <Hero />
        <ListingsSection />
        <Faqs :faqs="faqs" />
        <FeaturedPosts :posts="posts" />
        <SubscribeToNewsletter />
    </GuestLayout>
</template>
